<template >
  <v-app >
    <v-app-bar app class="custom-app-bar" color="primary" dark style="box-shadow:none;">
      <!-- Left column for logo -->
      <v-row justify="start">
        <v-col cols="auto">
          <v-img
            alt="HostHub Logo"
            src="@/assets/logo_white.png"
            height="38"
            width="200"
          />         
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-col v-if="isAdmin && isLoggedIn" cols="6" class="d-flex align-center">
        <v-row>
          <v-col>
            <v-autocomplete
              v-model="selectedCustomerId"
              :items="customers"
              item-text="text"
              item-value="value"
              :label="selectedCustomerId ? '' : $t('select_customer')"
              dense
              outlined
              class="pt-6"
              @change="onCustomerChange"
              hide-details
            ></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="selectedConfigurationId"
              :items="filteredConfigurations"
              item-text="text"
              item-value="value"
              :label="selectedConfigurationId ? '' : $t('select_configuration')"
              dense
              outlined
              class="pt-6"
              @change="onConfigurationChange"
              @input="loadConfigurationAdmin"
              hide-details
            ></v-autocomplete>
            <div class="d-flex justify-end pt-1">
              <v-checkbox
                dense
                v-model="includeInactive"
                hide-details
                :label="$t('include_inactive')"
                class="mt-0"
                @change="loadConfigurationsAdmin"
              ></v-checkbox>
            </div>
          </v-col>          
          <div v-if="!selectedConfigurationId" class="pt-5">           
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="confirmCreateConfigurationDialog = true">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('create_new_configuration')}}</span>
            </v-tooltip>
          </div>
        </v-row>
      </v-col>

      <v-spacer></v-spacer>

      <!-- Right column for language dropdown and logout button -->
      <v-row v-if="isLoggedIn" justify="end" class="zoom">
        <v-col cols="auto">
          <v-menu bottom left :close-on-content-click="false" v-model="userMenuOpen">
            <template v-slot:activator="{ on, attrs }">
              <div class="pt-8 pr-5" style="display: flex; flex-direction: column; align-items: center;">
                <v-badge
                  color="red"
                  :content="newNotifications"
                  overlap
                  offset-x="12"
                  offset-y="12"
                  :class="{ 'animate-badge': animateBadge }"
                  v-if="newNotifications > 0"> <!-- This conditionally renders the badge content -->
                  <v-btn class="button-border" dark icon v-bind="attrs" v-on="on">
                    <v-icon size="32px">mdi-account</v-icon>
                  </v-btn>
                </v-badge>
                <!-- Render the button without the badge when there are no new notifications -->
                <v-btn v-else class="button-border" dark icon v-bind="attrs" v-on="on">
                  <v-icon size="32px">mdi-account</v-icon>
                </v-btn>
                <div v-if="serviceDown" style="position: relative; display: flex; justify-content: center; align-items: center; margin-top: 8px;">
                  <v-tooltip v-if="serviceDown" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div style="position: relative; display: flex; justify-content: center; align-items: center; margin-top: 8px;" v-bind="attrs" v-on="on">
                        <v-icon :color="statusColor" style="font-size: 32px;">mdi-circle</v-icon>
                        <v-icon style="position: absolute; font-size: 16px; color: white;">mdi-alert</v-icon>
                      </div>
                    </template>
                    <span>{{statusMessage}}</span>
                  </v-tooltip>
                  <div v-else style="position: relative; display: flex; justify-content: center; align-items: center; margin-top: 8px;">
                    <v-icon :color="statusColor" style="font-size: 32px;">mdi-circle</v-icon>
                  </div>
                </div>
              </div>
            </template>
            <v-list>
              <v-list-item v-for="(item, i) in userMenuItems" :key="i" class="cursor-pointer">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-if="item.title === $t('menu.notifications')">
                  <v-list-item-title @click="notificationsDialogOpen = true; userMenuOpen = false;">{{ item.title }} <span v-if="newNotifications > 0">({{ newNotifications }})</span></v-list-item-title>
                </v-list-item-content>
                <!-- Conditional rendering for the logout item -->
                <v-list-item-content v-if="item.title === $t('menu.logout')">
                  <v-list-item-title @click="handleLogout">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
                <!-- Conditional rendering for the language selection -->
                <v-list-item-content v-else-if="item.title === $t('menu.language')">
                  <v-select
                    :label="$t('select_language')"
                    v-model="selectedLanguage"
                    :items="languages"
                    @change="changeLanguage"
                    dense
                    style="max-width: 200px;"
                  ></v-select>
                </v-list-item-content>
                <!-- Default rendering for other items -->
                <v-list-item-content v-else-if="isAdmin && item.title === $t('menu.settings')">
                  <v-list-item-title @click="settingsDialogOpen = true; userMenuOpen = false; getUsers()">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main>
      <v-tabs
        class="zoom"
        background-color="#1D0E3E"
        center-active
        dark
        centered
        v-if="isLoggedIn"
        v-model="activeTab"
      >
        <v-tab v-if="isAdmin" @click="onDashboardTabClicked">
          <v-icon color="#fff" size="30">mdi mdi-view-dashboard</v-icon>
          <div :class="{'white-text': true, 'font-weight-bold': activeTab === 0}" style="margin-left: 10px;">{{$t('dashboard')}}</div>
        </v-tab>
        <v-tab v-if="isAdmin" :disabled="selectedConfigurationId !== null">
          <v-icon color="#fff" size="30">mdi mdi-account-group</v-icon>
          <div :class="{'white-text': true, 'font-weight-bold': activeTab === 1}" style="margin-left: 10px;">{{$t('customers')}}</div>
        </v-tab>
        <v-tab v-if="isAdmin && selectedConfigurationId">
          <v-icon color="#fff" size="30">mdi mdi-tools</v-icon>
          <div :class="{'white-text': true, 'font-weight-bold': activeTab === 2}" style="margin-left: 10px;">{{$t('configuration')}}</div>
        </v-tab>
        <v-tab v-if="selectedConfigurationId">
          <v-icon color="#fff" size="30">mdi-forum-outline</v-icon>
          <div :class="{'white-text': true, 'font-weight-bold': activeTab === 3}" style="margin-left: 10px;">{{$t('conversations')}}</div>
        </v-tab>
        <v-tab v-if="selectedConfigurationId">
          <v-icon color="#fff" size="30">mdi-cogs</v-icon>
          <div :class="{'white-text': true, 'font-weight-bold': activeTab === 4}" style="margin-left: 10px;">{{$t('settings')}}</div>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab" v-if="isLoggedIn && !showWizard" class="zoom">
        <v-tab-item v-if="isAdmin">
          <DashboardTab/>
        </v-tab-item>
        <v-tab-item v-if="isAdmin">
          <CustomersTab/>
        </v-tab-item>
        <v-tab-item v-if="isAdmin">
          <ConfigurationsTab/>
        </v-tab-item>
        <v-tab-item>
          <ConversationsTab
            :configurationId="selectedConfigurationId"
            :configurationName="selectedConfigurationName"
            :currentProduct="currentProduct"/>
        </v-tab-item>
        <v-tab-item>
          <SettingsTab
          :configurationId="selectedConfigurationId"
          :hasActiveSubscription="hasActiveSubscription"/>
        </v-tab-item>
      </v-tabs-items>

      <div v-if="isLoggedIn && !showWizard && selectedConfigurationId" class="credits-container zoom">
        <v-card elevation="10">
          <v-container>
            <v-row justify="center" style="padding-bottom:10px;">
              <v-col cols="12" xl="4" lg="5" md="4" sm="5" xs="12">
                <v-card color="#f5f5f5" >
                  <v-card-text class="card-content">
                    <div class="headline">{{$t('monthly_messages')}}</div>
                    <div class="display-1 font-weight-bold">{{messageLimit}}</div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" xl="4" lg="5" md="4" sm="5" xs="12">
                <v-card color="#e8eaf6" >
                  <v-card-text class="card-content">
                    <div class="headline">{{$t('remaining_messages')}}</div>
                    <div class="display-1 font-weight-bold">{{remainingMessages}}</div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="center" class="my-2" v-if="!isAdmin">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="false"
                    @click="showSubscriptionSheet"
                    v-bind="attrs" v-on="on"
                    elevation="5"
                    class="upgrade-btn"
                    color="#E140A0">
                    {{$t('subscriptions')}}
                  </v-btn>
                </template>
                <span>{{$t('look_at_our_subcriptions')}}</span>
              </v-tooltip>
            </v-row>
          </v-container>
        </v-card>
      </div>

      <!-- TODO move to separate component-->
      <!-- <SubscriptionSheet ng-if="false" style="z-index: 1200;"></SubscriptionSheet> -->
      <v-bottom-sheet style="z-index: 1200;" v-model="showSubscriptions" >
        <v-sheet
          class="text-center zoomPricing"
        >
          <div>
            <v-row>
              <v-col class="d-flex justify-start">
              </v-col>
              <v-col class="d-flex justify-center">
                <v-switch
                  @change="setSubscriptionType"
                  inset
                ></v-switch>
                <h1 class="pink-text" style="padding-top:10px">
                  <span v-if="chosenSubscriptionType == 'monthly'">{{$t('monthly')}}</span>
                  <span v-if="chosenSubscriptionType == 'annual'">{{$t('annual')}}</span>
                </h1>
                <!-- <v-text-field
                    v-model="paymentId"
                    label="Payment ID"
                  ></v-text-field>
                  <v-btn @click="triggerWebhook">trigger webhook</v-btn>
                  <v-btn @click="cancelSubscriptions">Delete subs</v-btn> -->
              </v-col>
              <v-col class="d-flex justify-end">
                <v-btn icon @click="showSubscriptions = !showSubscriptions" class="close-button">
                  <v-icon size="36">mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <div class="pricing-card-wrapper">
               <div style="margin-top:50px;"
                class="pricing-card"
                >
                <div class="pricing-card-top-row" style="margin-left:50px;">
                  <div class="pricing-price-title">
                    <h3><span style="font-size: 30px;" class="blue-text">Entry</span></h3>
                  </div>
                  <div class="pricing-price-title-wrapper" style="margin-top:22px;">
                    <div>
                      <div class="pricing-price-div text-center">
                        <div class="price"><strong>€0</strong></div>
                        <span>{{$t('per_month')}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pricing-list-div">
                  <v-divider></v-divider>
                  <div style="padding:10px; display: flex; flex-direction: column; align-items: center;">
                    {{currentProduct}}
                    <v-btn style="width: auto; border:none;" :disabled="true" elevation="5" class="upgrade-btn" color="#E140A0">
                      <span v-if="currentProduct === 'Entry' || currentProduct === 'entry'">{{$t('current_product')}}</span>
                      <span v-if="currentProduct === 'basic' || currentProduct === 'Basic'">-</span>
                    </v-btn>
                    <div class="text-center" style="padding-top:10px;">
                      <h3 class="h6">65 {{$t('messages')}}</h3>
                      <span style="font-size:12px;">(100.000 {{$t('credits')}})</span>
                    </div>
                  </div>
                  <div class="pricing-list-wrappper" style="padding-top:35px;">
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('ai_support')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('fixed_design')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('conversation_overview')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('chat_for_website')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('ssl_secured')}}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div @click="chooseProduct('basic')"
                class="pricing-card"
                :class="[
                  { 'currentProduct': currentProduct === 'basic' }
                ]">
                <!-- <h1 class="h5 tags" style="background-color: #800080;">ZZP en MKB</h1> -->
                <div class="pricing-card-top-row" style="margin-left:50px; margin-top:50px;" >
                  <div class="pricing-price-title">
                    <h3><span style="font-size: 30px;" class="purple-text">Basic</span></h3>
                  </div>
                  <div class="pricing-price-title-wrapper" style="margin-top:22px;">
                    <div>
                      <div class="pricing-price-div text-center">
                        <div class="price">
                          <span v-if="chosenSubscriptionType == 'monthly'"><strong>€99</strong></span>
                          <span v-if="chosenSubscriptionType == 'annual'"><strong>€80</strong></span>
                        </div>
                        <span>{{$t('per_month')}}</span>
                      </div>
                    </div><img loading="lazy" src="images/basic-package.svg" alt="AI assistent " class="price-block-icon">
                  </div>
                </div>
                <div class="pricing-list-div">
                  <v-divider></v-divider>
                  <div style="padding:10px; display: flex; flex-direction: column; align-items: center;">
                    <v-btn style="width: auto; border-color: transparent;" :disabled="currentProduct === 'basic' || currentProduct === 'Basic'" @click="chooseProduct('basic')" elevation="5" class="upgrade-btn" color="#E140A0">
                      <span v-if="currentProduct === 'basic' || currentProduct === 'Basic'">{{$t('current_product')}}</span>
                      <span v-if="currentProduct === 'Entry' || currentProduct === 'entry'"> {{$t('start_now')}}</span>
                    </v-btn>
                    <div class="text-center" style="padding-top:10px;">
                      <h3 class="h6">500 {{$t('messages')}}</h3>
                      <span style="font-size:12px;">(750.000 {{$t('credits')}})</span>
                      <h3 class="h5">{{$t('everything_from')}} <strong>Entry+</strong></h3>
                    </div>
                  </div>
                   <div class="pricing-list-wrappper">
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('chat_support')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('your_own_design')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('conversation_history')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('100_languages')}}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="padding-top:90px;"
                class="pricing-card"
                :class="[
                  { 'currentProduct': currentProduct === 'premium' },
                ]">
                <div v-if="currentProduct === 'premium'" class="pricing-card-title"><span style="color:#ff33cc; font-weight:bolder;">Je huidige product</span></div>
                <!-- <div v-if="chosenProduct === 'premium'" class="pricing-card-title"><span style="color: #AF2C7B; font-weight:bolder;">Je gekozen product</span></div> -->
                <div class="pricing-card-top-row">
                  <div class="pricing-price-title">
                    <h3><span style="font-size: 30px;" class="pink-text">Premium</span></h3>
                  </div>
                  <div class="pricing-price-title-wrapper">
                    <div class="pricing-price-title-wrappe">
                      <div class="pricing-price-div text-center">
                        <span>{{$t('starting_from')}}</span>
                        <div class="price">
                          <span v-if="chosenSubscriptionType == 'monthly'"><strong>€199</strong></span>
                          <span v-if="chosenSubscriptionType == 'annual'"><strong>€160</strong></span>
                        </div>
                        <span>{{$t('per_month')}}</span>
                      </div>
                    </div><img loading="lazy" src="images/icon-1.svg" alt="Ai klantenservice positieve feedback" class="price-block-icon">
                  </div>
                </div>
                <div class="pricing-list-div">
                  <v-divider></v-divider>
                  <div style="padding-top:10px; display: flex; flex-direction: column; align-items: center;">
                    <v-btn style="width: auto;" @click="sendToContactPage" elevation="5" class="upgrade-btn" color="#E140A0">
                      {{$t('contact')}}
                    </v-btn>
                    <div class="text-center" style="padding-top:10px;">
                      <h3 class="h6">1500 {{$t('messages')}}</h3>
                      <span style="font-size:12px;">(2.250.000 {{$t('credits')}})</span>
                      <h3 class="h5">{{$t('everything_from')}} <strong>Basic+</strong></h3>
                    </div>
                  </div>
                  <div class="pricing-list-wrappper" style="margin-left:30px;">
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('priority_support')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('website_scraping')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('document_scraping')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('custom_design')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('onboarding_manager')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item" style="align-items: baseline;">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>Whatsapp, facebook, instagram & e-mail</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="pricing-card"
                :class="[
                  { 'currentProduct': currentProduct === 'professional' }
                ]">
                <div v-if="currentProduct === 'professional'" class="pricing-card-title"><span style="color:#ff33cc; font-weight:bolder;">{{$t('your_current_product')}}</span></div>
                <h1 class="h5 tags" style="background-color: #0AEBD6;">{{$t('most_chosen')}}</h1>
                <div class="pricing-card-top-row mr-0" style="padding-top:10px;">
                  <div class="pricing-price-title">
                    <h3><span style="font-size: 30px;" class="mintgreen-text">Professional</span></h3>
                  </div>
                  <div class="pricing-price-title-wrapper">
                    <div>
                      <div class="pricing-price-div text-center">
                        <span>{{$t('starting_from')}}</span>
                        <div class="price">
                          <span v-if="chosenSubscriptionType == 'monthly'"><strong>€599</strong></span>
                          <span v-if="chosenSubscriptionType == 'annual'"><strong>€480</strong></span>
                        </div>
                        <span>{{$t('per_month')}}</span>
                      </div>
                    </div><img loading="lazy" src="images/icon.svg" alt="AI klantenservice tevreden klant" class="image-3">
                  </div>
                </div>
                <div class="pricing-list-div">
                  <v-divider></v-divider>
                  <div style="padding:10px; display: flex; flex-direction: column; align-items: center;">
                    <v-btn style="width: auto;" @click="sendToContactPage" elevation="5" class="upgrade-btn" color="#E140A0">
                      {{$t('contact')}}
                    </v-btn>
                    <div class="text-center" style="padding-top:10px;">
                      <h3 class="h6">5000 {{$t('messages')}}</h3>
                      <span style="font-size:12px;">(7.500.000 {{$t('credits')}})</span>
                      <h3 class="h5">{{$t('everything_from')}} <strong>Premium+</strong></h3>
                    </div>
                  </div>
                  <div class="pricing-list-wrappper">
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('success_manager')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('prompting_service')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('custom_api')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('custom_ai_triggers')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('remove_branding')}}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="padding-top:90px;"
                class="pricing-card"
                :class="[
                  { 'currentProduct': currentProduct === 'custom' }
                ]">
                <div v-if="currentProduct === 'custom'" class="pricing-card-title"><span style="color:#ff33cc; font-weight:bolder;">{{$t('your_current_product')}}</span></div>
                <div class="pricing-card-top-row">
                  <div class="pricing-price-title">
                    <h3><span style="font-size: 30px;">Enterprise</span></h3>
                  </div>
                  <div class="pricing-price-title-wrapper">
                    <div class="pricing-price-title-wrappe">
                      <div class="pricing-price-div text-center">
                        <span>{{$t('starting_from')}}</span>
                        <div class="price"><strong>€1299,-</strong></div>
                        <span>{{$t('per_month')}}</span>
                      </div>
                    </div><img style="margin-left: 10px;" loading="lazy" src="images/rocket-launch.png" alt="Ai racket customer service" class="price-block-icon rckt">
                  </div>
                </div>
                <div class="pricing-list-div">
                    <v-divider></v-divider>
                  <div style="padding:10px; display: flex; flex-direction: column; align-items: center;">
                    <v-btn style="width: auto;" @click="sendToContactPage" elevation="5" class="upgrade-btn" color="#E140A0">
                      {{$t('contact')}}
                    </v-btn>
                    <div class="text-center" style="padding-top:10px;">
                      <h3 class="h6" style="font-size: 34px;"><span class="mdi mdi-all-inclusive"></span></h3>

                      <h3 class="h5">{{$t('everything_from')}} <strong>Professional+</strong></h3>
                    </div>
                  </div>
                  <div class="pricing-list-wrappper">
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('custom_credits')}}</strong></span>
                      </div>
                    </div>
                    <div class="main-item">
                      <span class="pink-text mdi mdi-check-circle mdi-24px"></span>
                      <div class="content">
                        <span class="body-small body-color"><strong>{{$t('custom_plan')}}</strong></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>

      <SelectedSubscriptionDialog
        :configurationId="selectedConfigurationId"
        :chosenProduct="chosenProduct"
        :chosenSubscriptionType="chosenSubscriptionType"
        v-if="showSelectedSubscription"/>

      <LoginDialog
        v-if="!isLoggedIn"
        @login-success="handleLoginSuccess"
        @start-wizard="showWizard = true"
      />
      <WizardDialog :configurationId="selectedConfigurationId" v-if="isLoggedIn && showWizard"
        v-model="showWizard"
        @wizard-completed="onWizardCompleted"
      />
      <!-- Notifications dialog -->
      <v-dialog v-model="notificationsDialogOpen" persistent max-width="90vw">
        <v-card>
          <v-card-title class="justify-center grey primary white--text">
            {{$t('notifications')}}
          </v-card-title>
          <v-card-text class="pt-4 text-center">
            <v-simple-table dense fixed-header height="200" style="border: 1px solid black;">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{$t('description')}}</th>
                    <th>{{$t('created_on')}}</th>
                    <th>{{$t('status')}}</th>
                    <th>{{$t('details')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(notification, index) in notifications" :key="index" :class="{'new-row': notification.is_new}">
                    <td><span>{{ notification.description }}</span></td>
                    <td><span>{{ notification.created_on }}</span></td>
                    <td><span>{{ notification.status }}</span></td>
                    <td><span>{{ notification.details }}</span></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="secondary" @click="notificationsDialogOpen = false">{{$t('close')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!--Settings dialog -->
      <v-dialog v-model="settingsDialogOpen" persistent max-width="90vw">
        <v-card>
          <v-card-title class="justify-center grey primary white--text">
            {{$t('settings')}}
          </v-card-title>
          <v-toolbar dense dark flat color="primary">
            <template>
              <v-tabs v-model="settingsTab" background-color="transparent">
                <v-tab>{{$t('users')}}</v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items v-model="settingsTab">
            <v-tab-item>
              <v-container>
                <v-card flat>
                  <v-row justify="center" class="pt-5">
                    <v-col cols="12">
                      <v-simple-table dense fixed-header height="200" style="border: 1px solid black;">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th>{{$t('username')}}</th>
                              <th>{{$t('role')}}</th>
                              <th>{{$t('customer')}}</th>
                              <th>{{$t('inactive')}}</th>
                              <th>{{$t('actions')}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(user, index) in users" :key="index">
                              <!-- Parameter Name -->
                              <td><span>{{ user.username }}</span></td>
                              <td><span>{{ user.role }}</span></td>
                              <td>
                                <v-autocomplete style="zoom:70%"
                                  v-if="user.role === 'customer'"
                                  v-model="user.customerId"
                                  :items="customers"
                                  item-text="text"
                                  item-value="value"
                                  :label="user.customerId ? '' : $t('select_customer')"
                                  dense
                                  outlined
                                  class="pt-6"
                                  @change="addOrUpdateUser(user)"
                                ></v-autocomplete>
                              </td>
                              <td>
                                <v-checkbox secondary dense
                                  v-model="user.inactive"
                                  @change="() => addOrUpdateUser(user)"
                                ></v-checkbox>
                              </td>
                              <td>
                                <v-btn style="zoom:70%" dense @click="confirmResetPassword(user._id)">{{$t('reset_password')}}</v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                  <v-row v-if="newUser && newUser.role">
                    <v-col cols="2">
                      <v-text-field
                        v-model="newUser.username"
                        :label="$t('username')"
                        type="email"
                        :rules="emailRules">
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="newUser.password"
                        :label="$t('password')"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append="showPassword = !showPassword"
                        autocomplete="new-password">
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        dense
                        v-model="newUser.languageCode"
                        :items="languages"
                        item-text="text"
                        item-value="value"
                        :label="$t('select_language')"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-select
                        dense
                        v-model="newUser.role"
                        :items="roles"
                        item-text="text"
                        item-value="value"
                        :label="$t('select_role')"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-autocomplete
                        v-if="newUser.role === 'customer'"
                        v-model="newUser.customerId"
                        :items="customers"
                        item-text="text"
                        item-value="value"
                        :label="$t('select_customer')"
                        dense
                        outlined
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="1">
                      <v-btn color="green" @click="addOrUpdateUser(newUser)"
                        :disabled="isSendActivationMailDisabled" dense>
                        <span style="color:white">{{$t('create')}}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions class="justify-center">
            <v-btn color="secondary" @click="settingsDialogOpen = false;">{{$t('close')}}</v-btn>
            <v-btn v-if="!newUser" icon @click="addNewUser">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Replace demo page -->
      <v-dialog v-model="confirmCreateConfigurationDialog" persistent max-width="600px">
        <v-card>
          <v-card-title class="justify-center grey primary white--text">
            {{$t('create_new_configuration_title')}}
          </v-card-title>
          <v-card-text class="pt-4 text-center">
            <v-label>{{$t('create_new_configuration_text')}}</v-label>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" @click="confirmCreateConfigurationDialog = false">{{$t('cancel')}}</v-btn>
            <v-btn color="secondary" @click="confirmCreateConfigurationAdmin">{{$t('ok')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
    <v-snackbar v-model="showSnackbar" :timeout="snackbarTimeout" class="text-center">
      <v-icon size="30" :color="snackbarColor">{{ snackbarIcon }}</v-icon>
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="showSnackbar = false"
        >
          {{$t('close')}}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showPromoSnackbar" :timeout="promoSnackbarTimeout" :color="'#12072E'" class="promo-snackbar">
      <div class="promo-snackbar-layout">
        <div class="promo-snackbar-icon-circle">
          <v-icon class="promo-snackbar-icon">mdi-plus</v-icon>
        </div>
        <div class="promo-snackbar-text">
          <div>
            <h3 class="promo-snackbar-title">{{promoSnackbarHeader}}</h3>
            <p>{{promoSnackbarText}}</p>
          </div>
        </div>
      </div>
      <template v-slot:action="{ attrs }" v-if="!hidePromoSnackbarButtons">
        <div class="promo-snackbar-actions">
          <v-btn color="pink" dark v-bind="attrs" @click="showPromoSnackbar = false; showSubscriptions = true;">{{$t('upgrade')}}</v-btn>
          <v-btn color="pink" text v-bind="attrs" @click="showPromoSnackbar = false; showSubscriptions = true;">{{$t('discover_more')}}</v-btn>
        </div>
      </template>
    </v-snackbar>
    <v-overlay :value="showOverlay" style="z-index: 2000;">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import CustomersTab from './components/CustomersTab.vue';
import DashboardTab from './components/DashboardTab.vue';
import ConfigurationsTab from './components/ConfigurationsTab.vue';
import ConversationsTab from './components/ConversationsTab.vue';

import SettingsTab from './components/SettingsTab.vue';
import LoginDialog from './components/dialogs/LoginDialog.vue';
import WizardDialog from './components/dialogs/WizardDialog.vue';
import SelectedSubscriptionDialog from './components/dialogs/SelectedSubscriptionDialog.vue';
//import SubscriptionSheet from './components/SubscriptionsSheet.vue';
import { EventBus } from './eventBus.js';
import axios from 'axios';

export default {
  name: 'App',

  components: {
    LoginDialog,
    WizardDialog,
    //HomeTab,
    DashboardTab,
    CustomersTab,
    ConfigurationsTab,
    ConversationsTab,
    //SubscriptionSheet,
    SelectedSubscriptionDialog,
    //CostsAndLimitsTab,
    SettingsTab
  },

  data() {
    return {
      isLoggedIn: false, // Controls the visibility of login
      selectedLanguage: localStorage.getItem('userLanguage') || 'en', // Get stored language or default to English,
      selectedCustomerId: null, // to be able to filter configurations
      selectedConfigurationId: null, // This will hold the _id of the selected configuration
      includeInactive: false,
      hasActiveSubscription: false,
      selectedConfigurationName: null,
      customers: [],
      configurations: [], // This will hold the fetched configurations
      showSnackbar: false,
      showPromoSnackbar: false,
      showSubscriptions: false,
      showSelectedSubscription : false,
      showOverlay: false,
      showWizard: false,
      snackbarText: '',
      snackbarColor: '',
      snackbarIcon: '',
      promoSnackbarHeader: '',
      promoSnackbarText: '',
      hidePromoSnackbarButtons: false,
      activeTab: 0,
      snackbarTimeout: 3000,
      promoSnackbarTimeout: 8000,
      currentProduct: 'entry',
      chosenProduct: '',
      chosenSubscriptionType: 'monthly',
      messageLimit: 0,
      remainingMessages: 0,
      paymentId: '',
      confirmCreateConfigurationDialog: false,
      settingsDialogOpen: false,
      notificationsDialogOpen: false,
      userMenuOpen: false,
      settingsTab: null,
      animateBadge: false,
      users: [],
      roles: [
        { text: this.$t('admin'), value: 'admin' },
        { text: this.$t('customer'), value: 'customer' }
      ],
      newUser: null,
      showPassword: false,
      notifications: [],
      newNotifications: 0,
      statusColor: 'grey',
      serviceDown: false,
      statusMessage: '',
      services: [
        { name: 'Chat Service', url: this.$config.chatServer + '/health' },
        { name: 'Configuration Service', url: this.$config.configurationServer + '/health' }
      ],

    };
  },

  computed: {
    isSendActivationMailDisabled() {
      // Check if username is missing or it's a customer without a customer ID
      if (!this.newUser.username || (this.newUser.role === 'customer' && !this.newUser.customerId)) {
        return true;
      }

      // Apply email rules
      const emailValidationResults = this.emailRules.map(rule => rule(this.newUser.username));
      console.log('** emailValidationResults', emailValidationResults);

      // Check for any rule that returned a string (indicating a validation error)
      return emailValidationResults.some(result => typeof result === 'string');
    },
    emailRules() {
      return [
        v => !!v || this.$t('email_required'),
        v => /.+@.+\..+/.test(v) || this.$t('invalid_email'),
      ];
    },
    backgroundClass() {
     return this.isLoggedIn ? 'normal-background' : 'logged-out-background';
    },
    languages() {
      return [
        { text: this.$t('language.english'), value: 'en' },
        { text: this.$t('language.dutch'), value: 'nl' },
        { text: this.$t('language.german'), value: 'de' },
        { text: this.$t('language.french'), value: 'fr' },
        { text: this.$t('language.spanish'), value: 'es' },
        { text: this.$t('language.portuguese'), value: 'pt' }
      ];
    },
    isAdmin() {
      return this.$store.state.userRole === 'admin';
    },

    userMenuItems() {
      let items = [
        { title: this.$t('menu.language'), icon: 'mdi-translate' },
        { title: this.$t('menu.notifications'), icon: 'mdi-bell' },
        { title: this.$t('menu.logout'), icon: 'mdi-logout' }
      ];

      // Conditionally add settings item if isAdmin is true
      if (this.isAdmin) {
        items.push({ title: this.$t('menu.settings'), icon: 'mdi-cog' });
      }

      return items;
    },
    selectedConfiguration: {
      get() {
        return this.$store.getters.selectedConfiguration;
      },
      set(value) {
        this.$store.dispatch('updateSelectedConfiguration', value);
      }
    },
    filteredConfigurations() {
      let filteredConfigs;
      // Check if a customer is selected and filter configurations based on that
      if (this.selectedCustomerId) {
        filteredConfigs = this.configurations.filter(c => c.customerId === this.selectedCustomerId);
      } else {
        filteredConfigs = this.configurations.slice(); // Use slice to create a copy of the array
      }

      // Prepend the 'None' option for resetting selection
      return [
        { text: this.$t('none'), value: null },
        ...filteredConfigs
      ];
    }
  },
  mounted() {
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  created() {

    this.checkHealth();
    this.interval = setInterval(this.checkHealth, 300000); // 5 minutes

    const token = localStorage.getItem('userToken');
    if (token) {
      this.validateToken();
    } else {
      this.isLoggedIn = false;
    }

    this.$i18n.locale = this.selectedLanguage; // Set the initial language

    EventBus.$on('show-snackbar', (data) => {
      const typeData = this.getSnackbarIcon(data.type);
      this.snackbarText = data.message;
      this.snackbarIcon = typeData.icon;
      this.snackbarColor = typeData.color; // Assuming you have a data property for this
      this.showSnackbar = true;
      this.snackbarTimeout = data.timeout !== undefined ? data.timeout : 3000;
    });

    EventBus.$on('show-promosnackbar', (data) => {
      this.promoSnackbarText = data.message;
      this.promoSnackbarHeader = data.header;
      this.promoSackbarTimeout = data.timeout !== undefined ? data.timeout : 3000;
      this.hidePromoSnackbarButtons = data.hideButtons
      this.showPromoSnackbar = true;
    });

    EventBus.$on('set-configuration', (selectedConfiguration) => {
      this.setConfiguration(selectedConfiguration); // sets the configuration when its selected in the configurations tab
    });

    EventBus.$on('close-selected-subscription', () => {
      this.showSelectedSubscription = false;
    });

    EventBus.$on('show-overlay', () => {
      this.showOverlay = true;
    });
    EventBus.$on('hide-overlay', () => {
      this.showOverlay = false;
    });
    EventBus.$on('load-configurations', () => {
      this.loadConfigurations();
    });
    EventBus.$on('save-configuration', (configuration) => {
      this.saveConfiguration(configuration);
    });
    EventBus.$on('show-wizard', () => {
      this.showWizard = true;
    });
    EventBus.$on('duplicate-configuration', (configuration) => {
      configuration._id = null;
      configuration.name = configuration.name + '(copy)';
      this.saveConfiguration(configuration);
      this.$store.dispatch('updateSelectedConfiguration', {});
      EventBus.$emit('set-configuration', {});
      EventBus.$emit('load-configurations');
    });
  },
  watch: {
    showWizard(newValue) {
      console.log('showWizard changed to:', newValue);
    }
  },
  methods: {
    async checkHealth() {
      let downServices = [];
      let allServicesUp = true;

      for (let service of this.services) {
        try {
          const response = await axios.get(service.url);
          if (response.data.status !== "UP") {
            downServices.push(service.name);
            allServicesUp = false;
          }
        } catch (error) {
          downServices.push(service.name);
          allServicesUp = false;
        }
      }

      if (allServicesUp) {
        this.statusColor = 'green';
        this.serviceDown = false;
        this.statusMessage = '';
      } else {
        this.statusColor = 'red';
        this.serviceDown = true;
        this.statusMessage = `Services down: ${downServices.join(', ')}`;
      }
    },

    async handleLoginSuccess() {
      await this.validateToken();
      this.isLoggedIn = true;
    },
    async validateToken() {
      const token = localStorage.getItem('userToken');
      if (token) {
        try {
          this.showOverlay = true;
          const response = await axios.post(this.$config.configurationServer + '/validate-token', { token });
          if (response.data.status === 'success') {
            const userRole = response.data.payload.role;
            this.$store.dispatch('updateUserRole', userRole);

            this.isLoggedIn = true;
            if (!this.isAdmin) { // only customers load their configuration
              await this.loadConfigurations();
            }
            else {
              await this.loadConfigurationsAdmin();
              await this.loadCustomersAdmin();
            }
          } else {
            this.handleLogout();
          }
        } catch (error) {
          this.handleLogout();
        } finally {
          this.showOverlay = false;
        }
      }
    },

    confirmCreateConfigurationAdmin() {
      this.createNewConfigurationAdmin();
      this.confirmCreateConfigurationDialog = false;
    },

    async createNewConfigurationAdmin() {
      // create empty configuration
      const configuration = {
        name: 'New configuration',
        "functions": [{}],
        "entitytypes_to_anonimize": [],
        "max_tokens": 1500,
      }
      await this.saveConfiguration(configuration);
      await this.loadConfigurationsAdmin();
      if (this.filteredConfigurations.length > 0) {
        const configurationIdToLoad = this.filteredConfigurations[this.filteredConfigurations.length - 1].value
        await this.loadConfigurationAdmin(configurationIdToLoad);
        this.activeTab = 2;
      } else {
        console.error("No configurations found after reload.");
      }
    },
    async setConfiguration(selectedConfiguration) {
      if (!selectedConfiguration) return;
      this.selectedConfigurationId = selectedConfiguration._id;

      this.selectedConfigurationName = selectedConfiguration.name;
      this.currentProduct = selectedConfiguration.current_product;
      this.messageLimit = selectedConfiguration.message_limit;
      this.remainingMessages = selectedConfiguration.remaining_messages;

      if (selectedConfiguration._id) {
        this.hasActiveSubscription = await this.retrieveHasActiveSubscription(selectedConfiguration._id);
      }
    },

    async loadConfigurationAdmin(configurationId) {
        if (this.isAdmin) {
            if (!configurationId) {
                // Clear the selected configuration from the store if the ID is undefined
                this.$store.dispatch('clearSelectedConfiguration');
                this.activeTab = 0; // Jump to the first tab
                EventBus.$emit('reload-dashboard', null);
                // Assume a method exists to reload the dashboard
                //this.reloadDashboard();
            } else {
                const fullConfiguration = await this.getConfigurationAdmin(configurationId);
                if (fullConfiguration) {
                    // Update the store with the loaded configuration
                    this.$store.dispatch('updateSelectedConfiguration', fullConfiguration);
                    EventBus.$emit('set-configuration', fullConfiguration);
                    EventBus.$emit('clearConversations');
                    EventBus.$emit('clear');
                    if (this.activeTab == 0) { // only reload dashboard in case active tab is the dashboard
                      EventBus.$emit('reload-dashboard', configurationId);
                    }
                } else {
                    console.warn('Failed to load configuration or configuration not found.');
                    // Optionally clear the previous configuration if the new one fails to load
                    this.$store.dispatch('clearSelectedConfiguration');
                }
            }
        }
    },

    async addNewUser() {
      const generatedPassword = this.generatePassword();
      this.newUser = {
        password: generatedPassword,
        role: 'customer',
        inactive: true
      };
      this.showPassword = false;
    },

    generatePassword() {
      const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";
      for (let i = 0; i < 8; i++) { // Generates a 10-character password
        const randomIndex = Math.floor(Math.random() * chars.length);
        password += chars.charAt(randomIndex);
      }
      return password;
    },

    async addOrUpdateUser(user) {
      EventBus.$emit('show-overlay');

      const token = localStorage.getItem('userToken');
        if (!token) {
          console.error('User token not found. Please log in.');
          return;
        }

        return axios.post(this.$config.configurationServer + '/users/add_or_update', user, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          if (response.data && response.status === 200) {
            EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('save_succesfull')});
            this.getUsers();
            this.newUser = null;
          } else {
            // Handle non-successful responses
            console.log('*** response.message', response.message)
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('processing_failed', response.message)});
          }
        })
        .catch(error => {
          const errorMessage = error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : error.message || "Unknown error";

          console.log('*** error: ', errorMessage);
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('processing_failed') + errorMessage});
        })
        .finally(() => {
          EventBus.$emit('hide-overlay');
        });
    },

    getUsers() {
      const token = localStorage.getItem('userToken');
      if (token) {
            EventBus.$emit('show-overlay');

            return axios.get(`${this.$config.configurationServer}/users`, {
            headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
            if (response.status === 200) {
              this.users = response.data.data;
            } else {
                console.log('Error: ', response);
            }
            })
            .catch(error => {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: error });
              return null;
            })
            .finally(() => {
              //this.getSubscription(configurationId, token);
              EventBus.$emit('hide-overlay');
            });
        } else {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('login_required') });
            return Promise.resolve(null);
        }
    },

    getConfigurationAdmin(configurationId) {
        const token = localStorage.getItem('userToken');
        if (token) {
            EventBus.$emit('show-overlay');

            const params = new URLSearchParams({
              configuration_id: configurationId
            }).toString();

            return axios.get(`${this.$config.configurationServer}/configuration?${params}`, {
            headers: { Authorization: `Bearer ${token}` }
            })
            .then(configResponse => {
            if (configResponse.status === 200) {
                return configResponse.data;
            } else {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') });
                return null;
            }
            })
            .catch(error => {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') + error });
              return null;
            })
            .finally(() => {
              //this.getSubscription(configurationId, token);
              EventBus.$emit('hide-overlay');
            });
        } else {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('login_required') });
            return Promise.resolve(null);
        }
    },
    async loadConfigurations() {
      let configurations = await this.fetchConfigurations();
      if (configurations) {
        if (configurations.length > 0) {
          this.setConfiguration(configurations[0]);

          if (!configurations[0].wizardCompleted && configurations[0].isAutoOnboarding) {
            // if the wizard is not yet completed, show it again for auto onboarding customers
            // also find a way to set the configuration object in the WwizardDialog maybe best to make it global
            this.showWizard = true;
          }
        } else {
          this.showWizard = true;
        }
        if (!this.showWizard) {
          if (this.currentProduct && this.currentProduct === 'entry') {
            EventBus.$emit('show-promosnackbar', {
              message: this.$t('promo.to_basic_text'),
              header: this.$t('promo.to_basic_header')
            });
          }
          if (this.currentProduct && this.currentProduct === 'basic') {
            EventBus.$emit('show-promosnackbar', {
              message: this.$t('promo.to_premium_text'),
              header: this.$t('promo.to_premium_header')
            });
          }
        }
      } else {
        this.showWizard = true;
      }
    },
    async loadConfigurationsAdmin() {
      const configs = await this.getConfigurationsAdmin();
      this.configurations = [
        { text: this.$t('none'), value: null }, // 'None' option with a null value
        ...configs.map(c => ({
          text: c.is_inactive ? `${c.name} (${this.$t('inactive_txt')})` : c.name,
          value: c._id,
          customerId: c.customerId
        }))
      ];
    },
    async loadCustomersAdmin() {
      const token = localStorage.getItem('userToken');
        if (token) {
            return axios.get(this.$config.configurationServer + '/customers', {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
            if (response.status === 200) {
              const data = response.data;
              this.customers = [
                { text: this.$t('none'), value: null },
                ...data.map(c => ({ text: c.name, value: c._id }))
              ];

            } else {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('data_retrieval_unsucces') });
                return [];
            }
            })
            .catch(error => {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('data_retrieval_unsucces') + error });
                this.customers = [];
            });
        } else {
            return Promise.resolve([]);
        }
    },
    getConfigurationsAdmin() {
      const token = localStorage.getItem('userToken');
      if (token) {
          const params = new URLSearchParams();
          params.append('includeInactive', this.includeInactive);
          return axios.get(this.$config.configurationServer + '/configurations', {
          headers: { Authorization: `Bearer ${token}` },
          params: params
          })
          .then(configResponse => {
          if (configResponse.status === 200) {
              return configResponse.data;
          } else {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') });
              return [];
          }
          })
          .catch(error => {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') + error });
          return [];
          });
      } else {
          return Promise.resolve([]);
      }
    },
    fetchConfigurations() {
      const token = localStorage.getItem('userToken');
      if (token) {
        return axios.get(this.$config.configurationServer + '/configurations_with_username', {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(configResponse => {
          if (configResponse.status === 200) {
            return configResponse.data;
          } else {
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') });
            return [];
          }
        })
        .catch(error => {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('configuration_unsucces') + error });
          return [];
        });
      } else {
        return Promise.resolve([]);
      }
    },
    async saveConfiguration(configuration) {
      const token = localStorage.getItem('userToken');
        if (!token) {
          console.error('User token not found. Please log in.');
          return;
        }
        return axios.post(this.$config.configurationServer + '/configuration/create_or_update', configuration, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          console.log('Save config response:', response.data);
          if (response.data && response.status === 200) {
            EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('save_succesfull')});

            if (!configuration._id) {// in case of new configuration load the saved configuration with the inserted_id
              EventBus.$emit('load-configuration', response.data.inserted_id);
            } else { // already existing and loaded configuration
              EventBus.$emit('load-configuration', this.selectedConfiguration._id);
            }

          } else {
            // Handle non-successful responses
            EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('save_failed:', response)});
          }
        })
        .catch(error => {
          EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('save_failed:', error)});
        });
    },
    retrieveHasActiveSubscription(configurationId) {
      const token = localStorage.getItem('userToken');
      if (!token) {
        console.error('User token not found.');
        return Promise.resolve(false);
      }

      if (!configurationId) {
        console.error('Configuration ID is required.');
        return Promise.resolve(false);
      }

      return axios.get(this.$config.configurationServer + '/orders/has-active-subscription', {
        headers: { Authorization: `Bearer ${token}` },
        params: { configuration_id: configurationId }
      })
      .then(response => {
        // Assuming the endpoint returns a JSON object with a boolean property indicating subscription status
        if (response.status === 200 && response.data && typeof response.data.isActive === 'boolean') {
          return response.data.isActive;
        } else {
          console.error('Unexpected response structure:', response);
          return false;
        }
      })
      .catch(error => {
        console.error('Error checking active subscription:', error);
        return false;
      });
    },

    changeLanguage(newLang) {
      this.$i18n.locale = newLang; // Change the i18n language
      this.$vuetify.lang.current = newLang; // Update Vuetify's language
      localStorage.setItem('userLanguage', newLang); // Store the selection
      this.userMenuOpen = false;
    },
    getSnackbarIcon(type) {
      const types = {
        'INFO': { icon: 'mdi-information', color: 'grey' },
        'SUCCESS': { icon: 'mdi-check', color: 'green' },
        'WARN': { icon: 'mdi-alert', color: 'yellow' },
        'ERROR': { icon: 'mdi-close-circle', color: 'red' }
      };
      return types[type] || { icon: 'mdi-information', color: 'green' };
    },
    handleLogout() {
      localStorage.removeItem('userToken');
      localStorage.removeItem('userConfigurations'); // Clear configurations from local storage
      this.isLoggedIn = false;
      this.userMenuOpen = false;
    },
    onWizardCompleted() {
      this.showWizard = false;
      this.activeTab = 2; // Point to conversations tab
      this.loadConfigurations();
    },
    showSubscriptionSheet() {
      this.showSubscriptions = true;
    },
    chooseProduct(product) {
      if (this.currentProduct !=  product) { // only set to chosen if the current product is not already the chosen product
        this.chosenProduct = product;
        this.showSelectedSubscription = true;
        this.showSubscriptions = false;
      }
    },
    sendToContactPage() {
      window.open('https://www.hosthub.ai/contact.html', '_blank');
    },
    setSubscriptionType() {
      if (this.chosenSubscriptionType == 'annual') {
        this.chosenSubscriptionType = 'monthly';
      }
      else {
        this.chosenSubscriptionType = 'annual';
      }
    },
    onDashboardTabClicked() {
      if (this.selectedConfiguration._id) {
        EventBus.$emit('reload-dashboard', this.selectedConfiguration._id);
      } else {
        EventBus.$emit('reload-dashboard', null);
      }

    },
    onConfigurationChange(configurationId) {
      if (!configurationId) {
        this.selectedCustomerId = null
      } else {
        const configuration = this.configurations.find(c => c.value === configurationId);
        this.selectedCustomerId = configuration.customerId;
      }
    },
    onCustomerChange(newCustomerId) {
      if (!newCustomerId) {
        this.$store.dispatch('clearSelectedConfiguration');
        this.activeTab = 0; // Jump to the first tab
        this.selectedConfigurationId = null;
        this.selectedCustomerId = null
        EventBus.$emit('reload-dashboard', null);
        return;
      }
      // Filter configurations to find those that belong to the selected customer
      const customerConfigurations = this.configurations.filter(c => c.customerId === newCustomerId);

      // Check if there are any configurations for the selected customer
      if (customerConfigurations.length > 0) {
        // Automatically select the first configuration for this customer
        this.selectedConfigurationId = customerConfigurations[0].value;
        this.loadConfigurationAdmin(this.selectedConfigurationId);
      } else {
        // Reset the selected configuration if no configurations are found for the selected customer
        this.selectedConfigurationId = null;
      }
    },
    // TEST METHODS ONLY
    async cancelSubscriptions() {
      let paymentId = this.paymentId;
      const formData = new URLSearchParams();
        formData.append('id', paymentId);

        const response = await axios.post(
            this.$config.configurationServer + '/orders/cancel_subscriptions',
            formData
        );
        console.log(response.data);
    },
    async triggerWebhook() {
        let paymentId = this.paymentId;
        const formData = new URLSearchParams();
        formData.append('id', paymentId);

        const response = await axios.post(
            this.$config.configurationServer + '/orders/payments_webhook',
            formData
        );
        console.log(response.data);
    },
    confirmResetPassword(userId) {
      if (window.confirm(this.$t('user_password_reset_text'))) {
        // Call the method to reset the password
        this.resetPassword(userId);
      }
    },
    confirmSetInactive(userId) {
      if (window.confirm(this.$t('user_inactive_text'))) {
        // Call the method to deactivate the account
        this.setInactive(userId);
      }
    },
    resetPassword(userId) {
      // Actual password reset logic here
      console.log('Password reset initiated for user: ', userId);
    },
    setInactive(userId) {
      // Actual logic to set the account inactive
      console.log('Account set to inactive for user: ', userId);
    }
  }
};
</script>

<style scoped>
.credits-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 200;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  padding-bottom: env(safe-area-inset-bottom);
}

.credits-title {
  font-size: 2.0rem; /* or whatever size fits well */
  font-weight: bold;
  margin-top: 1rem; /* Adjust the top margin to vertically center with cards */
  margin-bottom: 1rem; /* Consistent space around the title */
}

.card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Adjust as necessary */
}

/* Extend padding as needed */
.v-card-text {
  padding: 2rem;
}

.v-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* This is an example; adjust as needed */
}

.upgrade-btn {
  border: 2px solid #AF2C7B;
  border-radius: 15px;
  color: white;
  font-weight: bolder;
  background-color: #E140A0;
}

/* Pricing classes */
.pricing-card-wrapper {
  width: 100%;
  background-color: var(--green-light);
  border-radius: 30px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: stretch;
  display: flex;
  overflow: hidden;
  box-shadow: 0 2px 50px 15px rgba(20, 18, 33, .02);
}

.pricing-card {
  width: 100%;
  text-align: left;
  background-color: #fff;
  border-right: 1px solid #e6f4f1;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 45px;
  padding-bottom: 75px;
  display: flex;
  transition: transform 0.3s ease, background-color 0.3s ease;
  /* border: 1px solid #ddd; */
  position: relative;
}

.pricing-card-title {
  position: absolute;
  top: -10px; /* Adjust as necessary */
  left: 20px; /* Adjust as necessary */
  background: #ffffff; /* Same as your background color */
  padding: 0 10px;
  color: #333;
  font-size: 16px;
  z-index: 1;
  /* ... other styles you might want ... */
}

.pricing-card:hover {
  background-color: #f5f5f5; /* Slightly lighter or darker than the original */
  transform: scale(1.05); /* Slightly increase the size */
  z-index:1000;
}

.pricing-card._2nd {
  padding-top: 7px;
}

.pricing-card-top-row {
  padding-right: 45px;
}

.pricing-price-title-wrapper {
  grid-row-gap: 10px;
}

.pricing-price-title-wrapper {
  grid-row-gap: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.pricing-price-title {
  border-radius: 40px;
  line-height: 21px;
}

.price {
  letter-spacing: -.0675rem;
  font-family: Onest, sans-serif;
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 2.75rem;
}

.price-block-icon.rckt {
  width:50px;
  background-color: #f084c3;
  border-radius: 50%;
  padding: 5px;
}

.purple-text {
  color: #800080;
}
.yellow-text{
  color: #ffcc29;
  background-color: none;
}

.green-text {
  color: #2e6419;
}

.lightgreen-text {
  color: #6d925e;
}

.mintgreen-text {
  color: #0AEBD6
}

.pink-text {
  color: #E140A0;
}

.pink-background {
  background-color: #E140A0;
}

.red-text {
  color: #b92933
}

.lightred-text {
  color: #db7f85
}

.grey-text {
  color: #333
}

.blue-text {
  color: #1b69f0
}

.white-text {
  color: #fff;
}

.h5 {
  text-align: left;
  letter-spacing: -.013rem;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Onest, sans-serif;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2rem;
}

.h5.tags {
  font-weight: bolder;
  background-color: #AF2C7B;
  color: #fff;
  border-radius: 20px;
  margin-left: 20px;
  margin-right: auto;
  padding: 6px 20px 2px;
  line-height: 30px;
}

.checkmark {
  /* width: 22px;
  height: 22px;
  background-color: #fff;
  border: 1px solid #1c1e30;
  box-shadow: 1px 1px #1c1e30;
  border-radius: 50%; */
  flex-direction: row;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;

}

.point-wrapper {
  width: 100%;
  max-width: 210px;
  grid-column-gap: 14px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 43px;
  display: flex;
}

.main-item {
  display: flex;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 4px; /* Adjust as needed */
}

.sub-item {
  font-size: 12px;
  margin-top: 0px; /* Adjust as needed for spacing */
}

.point-wrapper.no-max-width {
  max-width: none;
}

.currentProduct {
  border-color: ffb3ec;
  border-width: 5px;
}

.chosenProduct {
  border-color: purple;
  border-width: 5px;
}

.body-small {
    display: flex;
    flex-direction: column;
}

.item {
    display: flex;
    align-items: flex-start;
}

.custom-snackbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6A1B9A; /* Dark purple background */
}

.snackbar-content {
  display: flex;
  align-items: center;
}

.promo-snackbar {
  padding: 10px;
}

.promo-snackbar-layout {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.promo-snackbar-icon-circle {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E140A0; /* Pink background */
  border-radius: 50%;
  width: 36px; /* Adjust as necessary */
  height: 36px; /* Adjust as necessary */
  margin-right: 10px;
}

.promo-snackbar-icon {
  color: #FFFFFF;
}

.promo-snackbar-text {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.promo-snackbar-title {
  color: #FFFFFF;
  margin: 0;
}

.promo-snackbar-text p {
  color: #FFFFFF;
  margin: 0;
}

.promo-snackbar-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px; /* Adjust as necessary */
}

.promo-snackbar-actions .v-btn {
  margin-top: 5px; /* Adjust as necessary */
}

.cursor-pointer {
  cursor: pointer;
}

.button-border {
  border: 1px solid #b4b4b453;
}

th, td {
  width: 25%;
  text-align: left; /* or center, depending on preference */
  overflow: hidden; /* Ensures content does not overflow */
  text-overflow: ellipsis; /* Adds an ellipsis if the text is too long to fit */
  white-space: nowrap; /* Prevents text from wrapping */
}

.new-row {
  font-weight: bold;
  font-size: 1.2em;
}

@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.animate-badge .v-badge__badge {
  animation: zoomIn 0.6s ease-out forwards;
}

@media (max-width: 1000px) {
  .zoomPricing {
    zoom: 90%;
  }
}

@media (max-width: 950px) {
  .zoomPricing {
    zoom: 85%;
  }
}


@media (max-width: 900px) {
  .zoomPricing {
    zoom: 80%;
  }
}

@media (max-width: 850px) {
  .zoomPricing {
    zoom: 75%;
  }
}


@media (max-width: 800px) {
  .zoom {
    zoom: 95%;
  }

  .zoomPricing {
    zoom: 70%;
  }
}


@media (max-width: 750px) {
  .zoom {
    zoom: 90%;
  }

  .zoomPricing {
    zoom: 65%;
  }
}

@media (max-width: 700px) {
  .zoom {
    zoom: 85%;
  }

  .zoomPricing {
    zoom: 60%;
  }
}

@media (max-width: 650px) {
  .zoom {
    zoom: 80%;
  }

  .zoomPricing {
    zoom: 55%;
  }
}


@media (max-width: 600px) {
  .zoom {
    zoom: 70%;
  }

  .zoomPricing {
    zoom: 50%;
  }
}

@media (max-width: 500px) {
  .zoom {
    zoom: 60%;
  }
   .zoomPricing {
    zoom: 40%;
  }
}

@media (max-width: 400px) {
  .zoom {
    zoom: 60%;
  }
}

@media (max-width: 300px) {
  .zoom {
    zoom: 60%;
  }
}

</style>
