<template>
  <v-container class="pt-5">
    <v-card flat>
      <v-row>
        <v-col cols="3">
          <v-tabs vertical v-model="instructionsTab" background-color="white" color="primary">
            <v-tab key="entity">{{$t('entity')}}</v-tab>
            <v-tab key="goal">{{$t('goal')}}</v-tab>
            <v-tab key="answer">{{$t('answer')}}</v-tab>
            <v-tab key="general-information">{{$t('general_information')}}</v-tab>
            <v-tab :disabled="!selectedConfiguration.use_gpt35_url_search" key="url-search">{{$t('gpt35_url_search')}}</v-tab>
            <v-tab :disabled="!selectedConfiguration.use_email" key="email">{{$t('email')}}</v-tab>
            <v-tab v-if="selectedConfiguration.rag_enabled && !selectedConfiguration.use_only_rag"
              key="rag-recognition">{{$t('rag_recognition')}}
            </v-tab>
            <v-tab v-if="selectedConfiguration.rag_enabled" key="rag-search">{{$t('rag_search')}} <strong style="color:red">*</strong></v-tab>
            <v-tab v-if="selectedConfiguration.rag_enabled" key="rag-example-questions">{{$t('rag_example_questions')}}</v-tab>
            <v-tab key="labeling">{{$t('automatic_labeling')}}<strong style="color:red">*</strong></v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="9">
          <v-tabs-items v-model="instructionsTab">
            <v-tab-item key="entity">
              <v-textarea dense height="375"
                v-model="selectedConfiguration.entity_instruction"
                outlined
                required
                :error="!selectedConfiguration.entity_instruction"
              ></v-textarea>
            </v-tab-item>
            <v-tab-item key="goal">
              <v-textarea dense height="375"
                v-model="selectedConfiguration.goal_instruction"
                outlined
                required
                :error="!selectedConfiguration.goal_instruction"
              ></v-textarea>
            </v-tab-item>
            <v-tab-item key="answer">
                <v-textarea dense height="375"
                v-model="selectedConfiguration.answer_instruction"
                outlined
                required
                :error="!selectedConfiguration.answer_instruction"
              ></v-textarea>
            </v-tab-item>
            <v-tab-item key="general-information">
              <v-textarea dense height="375"
                v-model="selectedConfiguration.general_information_instruction"
                outlined
              ></v-textarea>
            </v-tab-item>
            <v-tab-item key="url-search">
              <v-textarea dense height="375"
                v-model="selectedConfiguration.url_search_instruction"
                outlined>
              </v-textarea>
            </v-tab-item>
            <v-tab-item key="email">
              <v-textarea dense height="375"
                v-model="selectedConfiguration.email_instruction"
                outlined
              ></v-textarea>
            </v-tab-item>
            <v-tab-item v-if="selectedConfiguration.rag_enabled && !selectedConfiguration.use_only_rag" key="rag-recognition">
              <v-textarea dense height="375"
                v-model="selectedConfiguration.rag_instruction"
                outlined
              ></v-textarea>
            </v-tab-item>
            <v-tab-item v-if="selectedConfiguration.rag_enabled" key="rag-search">
              <v-textarea dense height="375"
                v-model="selectedConfiguration.rag_search_instruction"
                outlined
              ></v-textarea>
            </v-tab-item>
            <v-tab-item v-if="selectedConfiguration.rag_enabled" key="rag-example-questions">
              <v-simple-table dense fixed-header height="auto" style="border: 1px solid black;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">{{$t('example_question')}}</th>
                      <th class="text-left">
                        <v-btn @click="addExampleQuestion" icon small>
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in selectedConfiguration.intent_example_questions" :key="index">
                      <td v-if="!item.editing" @click="item.editing = true">{{ item.text }}</td>
                      <td v-if="item.editing">
                        <v-textarea dense v-model="item.text" outlined @blur="item.editing = false"></v-textarea>
                      </td>
                      <td>
                        <v-btn icon small @click="deleteExampleQuestion(index)">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                        <v-btn icon small v-if="item.editing" @click.stop="applyChanges">
                          <v-icon>mdi-check</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
            <v-tab-item key="labeling">
              <v-textarea dense height="375"
                v-model="selectedConfiguration.labeling_instruction"
                outlined
              ></v-textarea>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-row justify="center" class="pb-3">
            <v-col class="mt-3" cols="2">
              <v-btn @click="saveChanges"
                :disabled="!hasConfigurationChanges"
                color="primary"
                >{{$t('save')}}
              </v-btn>
            </v-col>
            <v-col class="mt-3" cols="2">
              <v-btn @click="cancelChanges"
                :disabled="!hasConfigurationChanges"
                color="secondary"
                >{{$t('cancel')}}
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedVersion"
                :items="versionOptions"
                :label="$t('version')"
                item-text="name"
                @change="changeVersion"
              ></v-select>
            </v-col>
            <v-col class="mt-3" cols="2">
              <v-btn @click="createVersion"
                :disabled="hasConfigurationChanges || versionOptions.length === 2"
                color="secondary"
                >{{$t('new_version')}}
              </v-btn>
            </v-col>
          </v-row>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
    import { EventBus } from '../../eventBus.js';
    import axios from 'axios';
    export default {
        name: 'AiInstructionsTab',
        data: () => ({
          initialConfiguration: null,
          instructionsTab: null,
          selectedVersion: null,
          versionOptions: []
        }),
        computed: {
          selectedConfiguration: {
            get() {
              return this.$store.getters.selectedConfiguration;
            },
            set(value) {
              this.$store.dispatch('updateSelectedConfiguration', value);
            }
          },
          hasConfigurationChanges() {
            const result = JSON.stringify(this.selectedConfiguration) !== JSON.stringify(this.initialConfiguration);
            if (result) {
              EventBus.$emit('disable-tabs', true);
            }
            else {
              EventBus.$emit('disable-tabs', false);
            }
            return result;
          },
        },
        mounted() {
            this.initialize();
        },
        created() {
          EventBus.$on('set-configuration', () => {
            this.initialize();
          });
        },
        methods: {
          async initialize() {
            this.initialConfiguration = JSON.parse(JSON.stringify(this.selectedConfiguration));
            const versions = await this.getInstructionVersions();
            if (!versions || versions.length === 0) {
                this.createVersion();
            } else {
              this.versionOptions = versions;
              this.selectedVersion = this.selectedConfiguration.instruction_version;
            }
          },
          cancelChanges() {
            if (this.hasConfigurationChanges) {
              this.selectedConfiguration = JSON.parse(JSON.stringify(this.initialConfiguration));
            }
          },
          async saveChanges() {
            if (this.hasConfigurationChanges) {
              EventBus.$emit('save-configuration', this.selectedConfiguration);
              this.updateVersion();
            }
          },
          addExampleQuestion() {
            const newExampleQuestion = {
              text: '...',
              editing: true
            };
            this.selectedConfiguration.intent_example_questions.push(newExampleQuestion);
          },
          deleteExampleQuestion(index) {
            if (index >= 0 && index < this.selectedConfiguration.intent_example_questions.length) {
              this.selectedConfiguration.intent_example_questions.splice(index, 1);
            }
          },
          applyChanges(index) {
            if (index >= 0 && index < this.selectedConfiguration.intent_example_questions.length) {
              this.selectedConfiguration.intent_example_questions[index].editing = false;
            }
          },
          changeVersion() {
            const versionObject = this.versionOptions.find(option => option.name ===  this.selectedVersion);
            if (versionObject) {
              this.selectedConfiguration.instruction_version = versionObject.name;
              this.selectedConfiguration.entity_instruction = versionObject.entity_instruction || "";
              this.selectedConfiguration.goal_instruction = versionObject.goal_instruction || "";
              this.selectedConfiguration.answer_instruction = versionObject.answer_instruction || "";
              this.selectedConfiguration.general_information_instruction = versionObject.general_information_instruction || "";
              this.selectedConfiguration.url_search_instruction = versionObject.url_search_instruction || "";
              this.selectedConfiguration.email_instruction = versionObject.email_instruction || "";
              this.selectedConfiguration.rag_instruction = versionObject.rag_instruction || "";
              this.selectedConfiguration.rag_search_instruction = versionObject.rag_search_instruction || "";
              this.selectedConfiguration.intent_example_questions = versionObject.intent_example_questions || [];
              this.selectedConfiguration.labeling_instruction = versionObject.labeling_instruction || "";
            }
          },
          getInstructionVersions() {
            const token = localStorage.getItem('userToken');
            return axios.get(`${this.$config.configurationServer}/configuration/get_instruction_versions`, {
              params: { configuration_id: this.selectedConfiguration._id },
              headers: { Authorization: `Bearer ${token}` },
            })
            .then(response => {
              if (response.data) {
                console.log('versions: ', response.data);
                return response.data;
              }
            })
            .catch(error => {
              console.log('*** error: ', error);
              throw error; // Re-throw the error to allow further handling if needed
            });
          },

          async updateVersion() {
            const versionToUpdate = {
              configuration_id: this.selectedConfiguration._id,
              name: this.selectedConfiguration.instruction_version,
              entity_instruction: this.selectedConfiguration.entity_instruction || "",
              goal_instruction: this.selectedConfiguration.goal_instruction || "",
              answer_instruction: this.selectedConfiguration.answer_instruction || "",
              general_information_instruction: this.selectedConfiguration.general_information_instruction || "",
              url_search_instruction: this.selectedConfiguration.url_search_instruction || "",
              email_instruction: this.selectedConfiguration.email_instruction || "",
              rag_instruction: this.selectedConfiguration.rag_instruction || "",
              rag_search_instruction: this.selectedConfiguration.rag_search_instruction || "",
              labeling_instruction: this.selectedConfiguration.labeling_instruction || ""
            };

            if (this.selectedConfiguration.intent_example_questions && this.selectedConfiguration.intent_example_questions.length > 0) {
              versionToUpdate.intent_example_questions = JSON.parse(JSON.stringify(this.selectedConfiguration.intent_example_questions));
            }

            this.saveVersion(versionToUpdate);
          },

          async saveVersion(versionToSave) {
            const token = localStorage.getItem('userToken');
            try {
              const response = await axios.post(`${this.$config.configurationServer}/configuration/create_instruction_version`, versionToSave, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json'  // Ensure Content-Type is set to application/json
                }
              });

              if (response.status === 200) {
                EventBus.$emit('show-snackbar', { type: 'SUCCESS', message: this.$t('version_saved') });
                this.versionOptions = await this.getInstructionVersions(); // refresh list

              } else {
                EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('save_failed') });
              }
            } catch (error) {
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('save_failed') + error });
            }
          },

          async createVersion() {
            const now = new Date();
            const dateTimeNow = now.toISOString().replace(/[:.]/g, '-');
            const versionName = this.versionOptions.length === 0 ? `version_1_${dateTimeNow}` : `version_${this.versionOptions.length + 1}_${dateTimeNow}`;

            const newVersion = {
              configuration_id: this.selectedConfiguration._id,
              name: versionName,
              entity_instruction: this.selectedConfiguration.entity_instruction || "",
              goal_instruction: this.selectedConfiguration.goal_instruction || "",
              answer_instruction: this.selectedConfiguration.answer_instruction || "",
              general_information_instruction: this.selectedConfiguration.general_information_instruction || "",
              url_search_instruction: this.selectedConfiguration.url_search_instruction || "",
              email_instruction: this.selectedConfiguration.email_instruction || "",
              rag_instruction: this.selectedConfiguration.rag_instruction || "",
              rag_search_instruction: this.selectedConfiguration.rag_search_instruction || "",
              labeling_instruction: this.selectedConfiguration.labeling_instruction || ""
            };

            if (this.selectedConfiguration.intent_example_questions && this.selectedConfiguration.intent_example_questions.length > 0) {
              newVersion.intent_example_questions = JSON.parse(JSON.stringify(this.selectedConfiguration.intent_example_questions));
            }

            this.saveVersion(newVersion);
          }
        }
    }
</script>

<style scoped>
</style>

