<template>
  <v-container>
    <v-row class="text-center pt-5">
      <v-col cols="12">
        <v-card class="window-card">
          <v-row>            
            <v-col cols="12" md="6">
              <v-btn :disabled="!isAdmin"
                style="background-color: #f8bbd0; color: white;"
                block
                large
                class="mb-4 py-5 text-h4"
                elevation="4"
                height="200px"
                @click="showAISettings"
              >
                <div style="padding:20px;">
                  <span style="font-size: 50px; color:purple;" class="mdi mdi-database-import"></span>
                  <span style="padding-left:20px;">{{$t('data_import')}}</span> <br/>                
                </div>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">
              <v-btn
                style="background-color: #bbdefb; color: white;"
                block
                large
                class="mb-4 py-5 text-h4"
                elevation="4"
                height="200px"
                @click="showSubscription"
              >
                <div style="padding:20px;">
                  <span style="font-size: 50px; color:rgb(25, 159, 255);" class="mdi mdi-receipt-text"></span>
                  <span style="padding-left:20px;">{{$t('your_subscription')}}</span>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <active-subscription-dialog
      :dialog.sync="activeSubscriptionDialog"
      :activeSubscription="activeSubscription"
      @close-dialog="activeSubscriptionDialog = false"/>

    <selected-subscription-dialog
        :dialog.sync="selectedSubscriptionDialog"
        :configurationId="configurationId"
        :chosenProduct="chosenProduct"
        :chosenSubscriptionType="chosenSubscriptionType"
        :manualAmount="manualAmount"
        :manualYearDiscountPercentage="manualYearDiscountPercentage"
        @close-dialog="selectedSubscriptionDialog = false" />
    
    <data-import-dialog
        :dialog.sync="assistantSettingsDialog"
        :configurationId="configurationId"
        @close-dialog="assistantSettingsDialog = false" />
  </v-container>
</template>

<script>
import { EventBus } from '../eventBus.js';
import ActiveSubscriptionDialog from './dialogs/ActiveSubscriptionDialog.vue'
import SelectedSubscriptionDialog from './dialogs/SelectedSubscriptionDialog.vue';
import DataImportDialog from './dialogs/DataImportDialog.vue'; 
import axios from 'axios';

export default {
  name: 'SettingsTab',
  components: {
    ActiveSubscriptionDialog,
    SelectedSubscriptionDialog,
    DataImportDialog
  },
  props: {
    configurationId: String,
    hasActiveSubscription: Boolean
  },
  data: () => ({
    activeSubscriptionDialog: false,
    selectedSubscriptionDialog: false,
    assistantSettingsDialog: false,
    activeSubscription: null,
    selectedSubscription: null,
    chosenProduct: '',
    chosenSubscriptionType: '',
    manualAmount: null,
    manualYearDiscountPercentage: null
  }),
  computed: {
    isAdmin() {
      return this.$store.state.userRole === 'admin';
    },
  },
  methods: {
    showAISettings() {
      this.assistantSettingsDialog = true;   
    },
    async showSubscription() {
      EventBus.$emit('show-overlay');

      try {          
          const token = localStorage.getItem('userToken');

          const params = new URLSearchParams({
              configuration_id: this.configurationId
          }).toString();

          const response = await axios.get(`${this.$config.configurationServer}/orders/get-subscription?${params}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          let subscriptionData = response.data;          // if subscription_data has manual_amount it means that it's a custom subscription put together by us
          // in that case we use the existing mechanism in selectedSubscriptionDialog where users can subscribe to this manual subscription.          
          if (!this.hasActiveSubscription && subscriptionData.manual_amount) {
            this.selectedSubscription = subscriptionData;
            this.chosenProduct = subscriptionData.chosen_product;
            this.chosenSubscriptionType = subscriptionData.subscription_type;
            this.manualAmount = parseFloat(subscriptionData.manual_amount);
            this.manualYearDiscountPercentage = parseFloat(subscriptionData.year_discount);
            this.selectedSubscriptionDialog = true;
          } else {
            this.activeSubscription = subscriptionData;
            this.activeSubscriptionDialog = true;
          }

      } catch (error) {
          if (error.response && error.response.status === 404) {
              // Handle 404 Not Found
              EventBus.$emit('show-snackbar', { type: 'INFO', message: this.$t('no_subscription_found') });
          } else {
              // Handle other types of errors
              EventBus.$emit('show-snackbar', { type: 'ERROR', message: this.$t('error') + error });
          }
      } finally {
          EventBus.$emit('hide-overlay');
      }
    }
  },
}
</script>

<style scoped>
/* Add custom styles if needed */
</style>